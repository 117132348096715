import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Divider, Select, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const sortFilters = [
    {
        value: 'All',
        label: 'All',
    },
    {
        value: 'Used',
        label: 'Used',
    },
    {
        value: 'Unused',
        label: 'Unused',
    }
];

const inspector = [
    {
        value: 'Kendra Inspector',
        label: 'Kendra Inspector',
    },
    {
        value: 'Jhon Inspector',
        label: 'Jhon Inspector',
    },
];

const filters = [
    {
        value: 'Most used',
        label: 'Most used',
    },
    {
        value: 'Newest',
        label: 'Newest',
    },
    {
        value: 'Oldest',
        label: 'Oldest',
    },
    {
        value: 'File name',
        label: 'File name',
    }
];

const theme = createTheme({
    typography: {
        body1: {
            fontSize: '12px',
        },
    },
    customMenuItem: {
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        '&.Mui-selected': {
            backgroundColor: '#e0e0e0',
        },
    },
});

export function SortTextField({ handleFilterButtonChange, selectedType, setSelectedType, selectedId, sortType, RLInitialData }) {
    const handleChange = (e) => { setSelectedType(e.target.value); handleFilterButtonChange(selectedId, e.target.value, sortType, RLInitialData) }
    return (
        <div className="rl-filter">
            <ThemeProvider theme={theme}>
                <Select
                    value={selectedType}
                    onChange={handleChange}
                    className="rl-textfield"
                    sx={{ height: "37.5px" }}
                    MenuProps={{
                        MenuListProps: {
                            sx: {
                                px: '4px',
                                borderRadius: "4px",
                                border: "1px solid #E6E6E6",
                                minWidth: "150px",
                            }
                        },
                        PaperProps: { elevation: 0, sx: { mt: 1, gap: "8px" } }

                    }}

                    size='small'
                    renderValue={value => (<div style={{ overflow: "hidden", textOverflow: "ellipsis", width: 'inherit' }}><Typography noWrap>{value}</Typography></div>)}
                    startAdornment={<FilterListIcon className="rl-filter-icon" fontSize="small" sx={{ width: "13px", height: "13px", mr: "2px" }} />}
                >
                    {sortFilters.map((option) => (
                        <MenuItem
                            value={option.value}
                            selected={selectedType === option.value}
                            sx={{
                                marginBottom: "5px",
                                borderRadius: '8px',
                                height: "32px",
                                '&.Mui-selected': {
                                    backgroundColor: '#C7DBF6',
                                    color: 'white',
                                    border: '1px solid #4282E2',
                                    justifyContent: "space-between",
                                },
                                '&:hover': {
                                    backgroundColor: '#F3F4F5',
                                },
                            }}
                        >
                            <Typography className={selectedType === option.value ? 'filter-text-selected' : 'filter-text-option'}>{option?.label}</Typography>
                            {selectedType === option.value && <CheckIcon sx={{ color: "#2F1073" }} />}
                        </MenuItem>
                    ))}
                </Select>
            </ThemeProvider>
        </div>
    );
}

export function FilterSortTextField({ handleFilterButtonChange, selectedType, setSortType, selectedId, sortType, RLInitialData }) {
    const handleChange = (e) => { setSortType(e.target.value); handleFilterButtonChange(selectedId, selectedType, e.target.value, RLInitialData) }
    return (
        <div className="rl-filter">
            <ThemeProvider theme={theme}>
                <Select
                    value={sortType}
                    onChange={handleChange}
                    className="rl-textfield"
                    MenuProps={{
                        MenuListProps: {
                            sx: {
                                px: '4px',
                                borderRadius: "4px",
                                border: "1px solid #E6E6E6",
                                minWidth: "150px"
                            }

                        },
                        PaperProps: { elevation: 0, sx: { mt: 1, gap: "8px" } }

                    }}
                    size='small'
                    renderValue={value => (<div style={{ overflow: "hidden", textOverflow: "ellipsis", width: 'inherit' }}><Typography noWrap>{value}</Typography></div>)}
                    startAdornment={<SwapVertIcon className="rl-filter-icon" fontSize="small" sx={{ width: "13px", height: "13px", mr: "2px" }} />}
                >
                    {filters.map((option) => (
                        <MenuItem
                            value={option.value}
                            selected={sortType === option.value}
                            sx={{
                                marginBottom: "5px",
                                borderRadius: '8px',
                                height: "32px",
                                '&.Mui-selected': {
                                    backgroundColor: '#C7DBF6',
                                    color: 'white',
                                    border: '1px solid #4282E2',
                                    justifyContent: "space-between",
                                },
                                '&:hover': {
                                    backgroundColor: '#F3F4F5',
                                },
                            }}
                        >
                            <Typography className={sortType === option.value ? 'filter-text-selected' : 'filter-text-option'}>{option?.label}</Typography>
                            {sortType === option.value && <CheckIcon sx={{ color: "#2F1073" }} />}
                        </MenuItem>
                    ))}
                </Select>
            </ThemeProvider>
        </div>
    );
}