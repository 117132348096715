import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { v4 as uuidv4 } from "uuid";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "../../../Assets/Styles/Containers/_dashboard.scss";
import "../../../Assets/Styles/Containers/_inspection.scss";
import { updateInspectionDataCall } from "../../Redux/Actions/CommonActions";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import { updateISNTemplates } from "../../Helpers/ApiCall";
import Preloader from "../../Helpers/Preloader";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";

const ISNTemplateModal = ({ showISNTemplateModal, setShowISNTemplateModal }) => {
  const [masterTemplateData, setMasterTemplateData] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedTemplatesGuid, setSelectedTemplatesGuid] = useState([]);
  const [selectedTemplateType, setSelectedTemplateType] = useState([]);
  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const handleClose = () => {
    setShowISNTemplateModal(false);
  };

  const handleISNTemplateSelectionSelection = (template) => {
    let id = selectedTemplatesGuid.findIndex((elem) => elem === template.guid);
  
    if (id !== -1) {
      setSelectedTemplates((prev) => prev.filter((prev) => prev.guid !== template.guid));
      setSelectedTemplatesGuid((prev) => prev.filter((prev) => prev !== template.guid));
    } else {
      setSelectedTemplates((prev) => [...prev, template]);
      setSelectedTemplatesGuid((prev) => [...prev, template.guid]);
    }
  };
  
  const handleISNTemplateSave = async (selectedTemplates) => {
    let errorEl = document.getElementById("template_selection_error_text");
    if (selectedTemplatesGuid.length === 0) {
      errorEl.innerText = "Choose a template to start";
    } else {
      errorEl.innerText = "";
      const formData = {
        guid: sessionStorage.getItem("guid"),
        order_id: sessionStorage.getItem("guid"),
        inspection_from: "ISN",
        template_guid: selectedTemplatesGuid,
      };
      updateISNTemplates(dispatch, formData);
      setShowISNTemplateModal(false);
    }
  };

  useEffect(() => {
    if (storeData?.IsnMasterTemplates && storeData?.IsnMasterTemplates.length !== 0) {
      setMasterTemplateData(storeData?.IsnMasterTemplates);
    }
  }, [storeData?.IsnMasterTemplates]);

  return (
    <>
      <Dialog
        className="dialog-container-new-category"
        open={showISNTemplateModal}
        onClose={handleClose}
        aria-labelledby="category-modal"
        onBackdropClick="false"
        aria-describedby="category-modal-description"
      >
        <div className="close-wrapper-new-line"></div>
        <DialogTitle className="new-category-dialog-title mb-6">{"Choose templates"}</DialogTitle>
        <span className="category-dialog-subtitle p-custom">No templates have been assigned to the inspection. Choose templates below to start. </span>
        <DialogContent className="dialog-content-new-category">
          <Grid container className="input-controll">
            <Grid item xs={12}>
              {masterTemplateData?.map((template, index) => (
                <Typography onClick={() => handleISNTemplateSelectionSelection(template)} key={index} className={`bordered__text ${selectedTemplatesGuid?.includes(template?.guid) && "cat_selected"}`}>
                  {" "}
                  <span className="bordered__text__content" style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <span style={{ marginRight: "12px" }}>{template?.is_pdf_template === 1 ? <PictureAsPdfOutlinedIcon /> : null}</span>
                    {template?.name}
                  </span>
                  <DoneIcon className="cat_seleted_icon" />
                </Typography>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="template-selection-modal-action">
          <div id="template_selection_error_text"></div>
          <Button className="add-category-btn" onClick={() => handleISNTemplateSave(selectedTemplatesGuid)}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ISNTemplateModal;
