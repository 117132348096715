import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Paper,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import "./assign_color.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CLColorPicker from "./ColorPicker";
import { v4 as uuidv4 } from "uuid";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import { useDispatch } from "react-redux";
import { ApiCallForEditInspectorFlagList } from "../../Helpers/ApiCall";
import { FaRegTrashAlt } from "react-icons/fa";

const EditColorIndicator = ({ editColorIndicator, setEditColorIndicator, assignColorList, storeData, callInitiateAPI, closeModal }) => {
  const [rows, setRows] = useState(assignColorList);
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const lastAddedRowIndex = useRef(null); // Store the index of the newly added row

  useEffect(() => {
    // Check if a new row has been added and set focus on it
    if (lastAddedRowIndex.current !== null) {
      const lastRowIndex = lastAddedRowIndex.current;
      if (inputRefs.current[lastRowIndex]) {
        inputRefs.current[lastRowIndex].focus(); // Focus on the newly added row
      }
      lastAddedRowIndex.current = null; // Reset after focusing
    }
  }, [rows]);

  const handleClose = () => {
    setEditColorIndicator(false);
  };

  const handleAddRow = async () => {
    const newRow = {
      guid: uuidv4(),
      inspection_id: storeData.inspectionData?.data?.inspection_id,
      order: rows?.length + 1,
      is_deleted: 0,
      updated_at: await GetUTCTime(),
      name: "untitled",
      color_code: "#000001",
    };
    setRows((prevRows) => {
      const updatedRows = [...prevRows, newRow];
      lastAddedRowIndex.current = updatedRows.length - 1; // Track the newly added row's index
      return updatedRows;
    });
  };

  const handleInputChange = async (guid, field, value) => {
    const updatedTime = await GetUTCTime();
    setRows((prevRows) => prevRows.map((row) => (row.guid === guid ? { ...row, [field]: value, updated_at: updatedTime } : row)));
  };

  const handleEditInspectorFlagList = async () => {
    const formData = {
      inspection_id: storeData.inspectionData?.data?.inspection_id,
      inspectorFlagList: rows,
    };
    ApiCallForEditInspectorFlagList(dispatch, formData);
    setTimeout(callInitiateAPI, 2000);
    handleClose();
    closeModal();
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  };

  const headCells = [
    { id: "order", label: " " },
    { id: "title", label: "Tag" },
    { id: "color", label: "Color" },
    { id: "global", label: "" },
  ];

  return (
    <>
      <Dialog
        className="edit_color_indicator_container"
        open={editColorIndicator}
        onClose={handleClose}
        aria-labelledby="category-color-indicator-modal"
        aria-describedby="category-color-indicator-modal"
        sx={{
          "& .MuiDialog-paper": {
            width: "868px",
            height: "551px",
            maxWidth: "none",
          },
        }}
      >
        <div className="close-wrapper-new-category-right">
          <CloseIcon className="checkbox_container" onClick={handleClose} />
        </div>
        <DialogTitle className="edit-color-indicator-dialog-title">Edit color indicators</DialogTitle>
        <Divider height={2} />
        <DialogContent>
          <Paper sx={{ width: "100%" }} className="ci-table" elevation={0}>
            <TableContainer
              elevation={0}
              component={Paper}
              // style={{ overflow: "hidden" }}
              style={{ overflowY: "scroll", minHeight: "328px", maxHeight: "328px", minWidth: "700px" }}
            >
              <Table aria-label="simple table" sx={{ border: "1px solid #E5E8E8", borderRadius: "3px" }}>
                <TableHead>
                  <TableRow>
                    {headCells?.map((headCell) => (
                      <TableCell key={headCell.id} align={"left"} className="ci-table-head">
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {rows
                          ?.filter((row) => row.is_deleted !== 1)
                          ?.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow
                                hover
                                tabIndex={-1}
                                key={row.guid}
                                ref={provided.innerRef}
                                sx={{ cursor: "pointer" }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                height={"54px"}
                              >
                                <TableCell component="th" id={labelId} scope="row" size="small" padding="none" sx={{ padding: "20px 0px 0px 4px" }} className="ci-row-status ci-exp-col">
                                  <IconButton>
                                    <DragIndicatorIcon sx={{ color: "#878787", mt: 1.5 }} fontSize="small" />
                                  </IconButton>
                                </TableCell>
                                <TableCell padding="none" sx={{ p: "0px 10px 0px 0px" }} className="ci-title-col" align="left">
                                  <TextField
                                    inputRef={(el) => (inputRefs.current[index] = el)}
                                    className="ci_tags_textfield"
                                    defaultValue={row.name}
                                    sx={{ mb: 1 }}
                                    onChange={(e) => handleInputChange(row.guid, "name", e.target.value)}
                                  />
                                </TableCell>
                                <TableCell
                                  padding="none"
                                  className="ci-global-col"
                                  align="left"
                                  style={{
                                    width: "200px",
                                    position: "relative",
                                    alignContent: "center",
                                    zIndex: 1,
                                  }}
                                >
                                  <CLColorPicker row={row} onColorChange={handleInputChange} />
                                </TableCell>
                                <TableCell
                                  padding="none"
                                  align="center"
                                  width="160px"
                                  sx={{
                                    position: "relative",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <IconButton
                                    onClick={(e) => handleInputChange(row.guid, "is_deleted", 1)}
                                    sx={{
                                      color: "#D63D3D",
                                      width: "30px",
                                      height: "30px",
                                      border: "1px solid #E5E8E8",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <FaRegTrashAlt sx={{ color: "#D63D3D" }} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </Paper>
          <div
            style={{
              padding: "10px 20px",
              border: "1px solid #E5E8E8",
              borderRadius: "4px",
            }}
            onClick={handleAddRow}
          >
            <Button className="ci_add_button" startIcon={<AddCircleOutlineIcon />}>
              Add new
            </Button>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className="edit-color-indicator-action">
          <div className={`comment-library-checkbox-container`}></div>
          <div className="edit-color-indicator-btn">
            <Button className="ci-cancel-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" className="ci-save-btn" onClick={handleEditInspectorFlagList}>
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditColorIndicator;
